import { BottomBarModal, Button } from 'component';
import { useState } from 'react';
import { orderDataSlice } from 'state/orderSlice';
import { useAppDispatch } from 'state/store';
import './NewDeliveryModal.css';

export default function NewDeliveryModal({ onClose }: { onClose: () => void }) {
    // TODO: remove default values
    const [firstName, setFirstName] = useState('John');
    const [lastName, setLastName] = useState('Doe');
    const [address, setAddress] = useState('1234 North Capital, New York');
    const dispatch = useAppDispatch();

    const leftButtons = [<Button key="cancel" text={'Cancel'} onClick={onClose} />];

    const validInput = firstName && lastName && address;
    const addItemsOnClick = () => {
        if (validInput) {
            dispatch(orderDataSlice.actions.startDeliveryOrder({ firstName, lastName, address }));
            onClose();
        }
    };
    const rightButtons = [
        <Button key="addItems" text={'Add Items'} onClick={addItemsOnClick} disabled={!validInput} />,
    ];

    return (
        <BottomBarModal onClose={onClose} leftContent={leftButtons} rightContent={rightButtons}>
            <div className="NewDeliveryModalContent">
                <div className="NewDeliveryModalInputRow">
                    <div>Name:</div>
                    <input
                        className="NewDeliveryModalInput"
                        onChange={(event) => setFirstName(event.target.value)}
                        value={firstName}
                    />
                    <input
                        className="NewDeliveryModalInput"
                        onChange={(event) => setLastName(event.target.value)}
                        value={lastName}
                    />
                </div>
                <div className="NewDeliveryModalInputRow">
                    <div>Address:</div>
                    <input
                        className="NewDeliveryModalInput"
                        onChange={(event) => setAddress(event.target.value)}
                        value={address}
                    />
                </div>
            </div>
        </BottomBarModal>
    );
}
