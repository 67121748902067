import { LinkButton } from 'component';
import { cartSlice } from 'state/cartSlice';
import { getMenuItem, getMenuItemMod, getMenuItemModChoice } from 'state/restaurantSlice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { OrderMenuItem } from 'types/data-types';
import { formatPrice } from 'util/PriceUtil';
import c from './CartModal.module.css';

type CartItemProps = {
    index: number;
    orderMenuItem: OrderMenuItem;
};
function CartItem({ index, orderMenuItem }: CartItemProps) {
    const dispatch = useAppDispatch();
    const menuItem = getMenuItem(orderMenuItem.menuItemId);
    const modTexts = Object.entries(orderMenuItem.mods).map(([modId, selections]) => {
        const mod = getMenuItemMod(modId);
        const selectionText = selections.map((selection) => getMenuItemModChoice(modId, selection).name).join(', ');
        return `${mod.name}: ${selectionText}`;
    });

    return (
        <div className={c.CartItem}>
            <div className={c.CartItemContentLeft}>
                <div>{orderMenuItem.quantity}x</div>
            </div>
            <div className={c.CartItemContentMid}>
                <div className={c.CartItemHeader}>
                    <div>{menuItem.name}</div>
                </div>
                <div className={c.CartItemDetail}>
                    <div className={c.CartItemMod}>
                        {modTexts.map((t) => (
                            <div>{t}</div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={c.CartItemPrice}>{formatPrice(orderMenuItem.price)}</div>
            <div className={c.CartItemContentRight} onClick={() => dispatch(cartSlice.actions.removeCartItem(index))}>
                remove
            </div>
        </div>
    );
}

export default function CartModal() {
    const cartItems = useAppSelector((state) => state.cartData.cartItems);

    return (
        <div className={c.CartModal}>
            <div className={c.CartModelContent}>
                {cartItems.map((c, index) => (
                    <CartItem index={index} orderMenuItem={c} />
                ))}
                <LinkButton text="Checkout" to={'/checkout'} />
            </div>
        </div>
    );
}
