import { z } from 'zod';
import { BillSchema, MenuItemModSchema, MenuItemSchema, OrderSchema, RestaurantSchema } from './data-types';
import { DeepReadonly } from './util';

export enum DataState {
    Loading = 'Loading',
    Loaded = 'Loaded',
    Error = 'Error',
}

export const UserSchema = z.object({
    id: z.string(),
    username: z.string(),
});
export type User = DeepReadonly<z.infer<typeof UserSchema>>;

export const AuthResponseSchema = z.discriminatedUnion('success', [
    z.object({
        success: z.literal(true),
        data: z.object({
            accessToken: z.string(),
            user: UserSchema,
        }),
    }),
    z.object({
        success: z.literal(false),
        message: z.string(),
    }),
]);
export type AuthResponse = DeepReadonly<z.infer<typeof AuthResponseSchema>>;

export const GetRestaurantMenuResponseSchema = z.object({
    restaurant: RestaurantSchema,
    menuItems: z.array(MenuItemSchema),
    menuItemMods: z.array(MenuItemModSchema),
});
export type GetRestaurantMenuResponse = DeepReadonly<z.infer<typeof GetRestaurantMenuResponseSchema>>;

export const GetRestaurantsResponseSchema = z.object({
    restaurants: z.array(RestaurantSchema),
});
export type GetRestaurantsResponse = DeepReadonly<z.infer<typeof GetRestaurantsResponseSchema>>;

export const GetRecentBillsResponseSchema = z.object({
    bills: z.array(BillSchema),
    orders: z.array(OrderSchema),
});
export type GetRecentBillsResponse = DeepReadonly<z.infer<typeof GetRecentBillsResponseSchema>>;

export const CreateOrderFromEmployeeResponseSchema = z.object({
    bill: BillSchema,
    order: OrderSchema,
});
export type CreateOrderFromEmployeeResponse = DeepReadonly<z.infer<typeof CreateOrderFromEmployeeResponseSchema>>;
