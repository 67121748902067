import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import orderReducer from './orderSlice';
import restaurantReducer from './restaurantSlice';
import userReducer from './userSlice';
import cartSlice from './cartSlice';

export const store = configureStore({
    reducer: {
        userData: userReducer,
        restaurantData: restaurantReducer,
        orderData: orderReducer,
        cartData: cartSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
