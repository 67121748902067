import { Sidebar } from 'component';
import { BillList } from '../../component';
import './BillPage.css';

export default function BillPage() {
    return (
        <div className="BillPageContainer">
            <Sidebar>
                <BillList />
            </Sidebar>
        </div>
    );
}
