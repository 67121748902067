import { DataState } from 'types/api-types';
import { callGetRestaurantBills, callGetRestaurantMenuForId, callGetRestaurantMenuForOwner } from '../api';
import { orderDataSlice } from '../orderSlice';
import { restaurantDataSlice } from '../restaurantSlice';
import { AppDispatch, RootState } from '../store';
import { userDataSlice } from '../userSlice';

export function initializeAfterSignin(accessToken: string) {
    return (dispatch: AppDispatch) => {
        dispatch(userDataSlice.actions.signInSuccess(accessToken));
        callGetRestaurantMenuForOwner(accessToken)
            .then((data) => {
                dispatch(restaurantDataSlice.actions.onRestaurantDataLoaded(data));
                dispatch(getRestaurantOrders(accessToken, data.restaurant.id));
            })
            .catch((error) => {
                dispatch(restaurantDataSlice.actions.onRestaurantDataError('Network error'));
            });
    };
}

function getRestaurantOrders(accessToken: string, restaurantId: string) {
    return (dispatch: AppDispatch) => {
        callGetRestaurantBills(accessToken, restaurantId)
            .then((data) => {
                dispatch(orderDataSlice.actions.onOrderDataLoaded(data));
            })
            .catch((error) => {
                dispatch(orderDataSlice.actions.onOrderDataError('Network error'));
            });
    };
}

export function loadRestaurantData(restaurantId: string) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const restaurantData = getState().restaurantData.data;
        if (!(restaurantData.type === DataState.Loaded && restaurantData.restaurant.id === restaurantId)) {
            dispatch(restaurantDataSlice.actions.clearRestaurantData());
            callGetRestaurantMenuForId(restaurantId)
                .then((data) => {
                    dispatch(restaurantDataSlice.actions.onRestaurantDataLoaded(data));
                })
                .catch((error) => {
                    dispatch(restaurantDataSlice.actions.onRestaurantDataError('Network error'));
                });
        }
    };
}
