import { HeaderBar, LoadingSpinner, SelectMenuItem } from 'component';
import { useEffect, useState } from 'react';
import { loadRestaurantData } from 'state/actions/actions';
import { cartSlice } from 'state/cartSlice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { DataState } from 'types/api-types';
import { CartButton, SelectModModal } from '../../component';
import classes from './RestaurantPage.module.css';

type RestaurantPageProps = {
    restaurantId: string;
};

export default function RestaurantPage({ restaurantId }: RestaurantPageProps) {
    const dispatch = useAppDispatch();
    const restaurantData = useAppSelector((state) => state.restaurantData.data);

    const [initialCategory, setInitialCategory] = useState<string | null>(null);

    useEffect(() => {
        dispatch(loadRestaurantData(restaurantId));
    }, [dispatch, restaurantId]);

    if (!(restaurantData.type === DataState.Loaded && restaurantData.restaurant.id === restaurantId)) {
        const error = restaurantData.type === DataState.Error;
        const content = error ? 'Something is wrong. Please try again later.' : <LoadingSpinner />;
        return <div className={classes.RestaurantPageContainer}>{content}</div>;
    }

    const restaurantNameHeader = <div key="restaurantName">{restaurantData.restaurant.name}</div>;
    const restaurantAddressHeader = <div key="restaurantAddress">{restaurantData.restaurant.address}</div>;
    return (
        <div className={classes.RestaurantPageContainer}>
            <div className={classes.PageContainer}>
                <HeaderBar
                    childrenLeft={[restaurantNameHeader, restaurantAddressHeader]}
                    childrenRight={[<CartButton />]}
                />
                <SelectMenuItem
                    initialCategory={initialCategory}
                    onCategorySelect={setInitialCategory}
                    onSelectMenuItem={(menuItem) => dispatch(cartSlice.actions.setMenuItem(menuItem))}
                />
                <SelectModModal />
            </div>
        </div>
    );
}
