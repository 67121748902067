import { BillList, TableView } from '../../component';
import { Sidebar } from 'component';
import './RestaurantPage.css';

export default function RestaurantPage() {
    return (
        <div className="RestaurantPageContainer">
            <TableView />
            <Sidebar>
                <BillList />
            </Sidebar>
        </div>
    );
}
