import { LinkButton, LoadingSpinner } from 'component';
import { useEffect, useState } from 'react';
import { callGetRestaurants } from 'state/api';
import { Restaurant } from 'types/data-types';
import './HomePage.css';

export default function HomePage() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

    useEffect(() => {
        callGetRestaurants()
            .then((data) => {
                setRestaurants(data.restaurants);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            });
    }, []);

    let content;
    if (isLoading) {
        content = <LoadingSpinner />;
    } else if (error !== null) {
        content = <div>Cannot load restaurants. Please try again later.</div>;
    } else {
        content = (
            <div className="HomePageRestaurantsContainer">
                {restaurants.map((restaurant) => (
                    <div className="HomePageRestaurantButtonContainer" key={restaurant.id}>
                        <LinkButton to={`/restaurant/${restaurant.id}`} text={restaurant.name} />
                    </div>
                ))}
                <div className="HomePageSellerButtonContainer">
                    <LinkButton to={'/seller'} text={'Seller App'} />
                </div>
            </div>
        );
    }
    return <div className="HomePage">{content}</div>;
}
