import { useCallback, useRef, useState } from 'react';

/**
 * Hook to call prevent duplicate request.
 *
 * @param api to call
 * @returns callback and whether it is in progress
 */
export function useApiCall<I extends any[], O>(
    api: (...i: I) => Promise<O>,
): [(...i: I) => Promise<O> | null, boolean] {
    const promiseRef = useRef<Promise<O> | null>(null);
    const [inProgress, setInProgress] = useState(false);

    const call = useCallback(
        (...args: I) => {
            if (promiseRef.current) return null;

            setInProgress(true);
            promiseRef.current = new Promise<O>((resolve, reject) => {
                api(...args)
                    .then((data) => {
                        setInProgress(false);
                        promiseRef.current = null;
                        resolve(data);
                    })
                    .catch((error) => {
                        setInProgress(false);
                        promiseRef.current = null;
                        reject(error);
                    });
            });
            return promiseRef.current;
        },
        [promiseRef, setInProgress, api],
    );
    return [call, inProgress];
}
