import axios from 'axios';
import { DeliveryMethod, OrderMenuItem } from 'types/data-types';
import {
    AuthResponseSchema,
    CreateOrderFromEmployeeResponseSchema,
    GetRecentBillsResponseSchema,
    GetRestaurantMenuResponseSchema,
    GetRestaurantsResponseSchema,
} from '../types/api-types';

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL!!;

export async function callSignIn(username: string, password: string) {
    const { data } = await axios.post(SERVICE_URL + 'auth/login/', {
        data: {
            username,
            password,
        },
    });
    return AuthResponseSchema.parse(data);
}

export async function callGetRestaurants() {
    const { data } = await axios.post(SERVICE_URL + 'customer/getRestaurants/', {});
    return GetRestaurantsResponseSchema.parse(data);
}

export async function callGetRestaurantMenuForOwner(accessToken: string) {
    const { data } = await axios.post(SERVICE_URL + 'restaurant/getMenuForOwner/', {
        accessToken,
    });
    return GetRestaurantMenuResponseSchema.parse(data);
}

export async function callGetRestaurantMenuForId(restaurantId: string) {
    const { data } = await axios.post(SERVICE_URL + 'restaurant/getRestaurantForId/', {
        restaurantId,
    });
    return GetRestaurantMenuResponseSchema.parse(data);
}

export async function callGetRestaurantBills(accessToken: string, restaurantId: string) {
    const { data } = await axios.post(SERVICE_URL + 'order/getRecentBills/', {
        accessToken,
        query: {
            restaurantId,
        },
    });
    return GetRecentBillsResponseSchema.parse(data);
}

export async function callCreateOrderFromEmployee(
    accessToken: string,
    menuItems: OrderMenuItem[],
    billOption: {
        type: string;
        data:
            | {
                  restaurantId: string;
                  deliveryMethod: DeliveryMethod;
              }
            | {
                  billId: string;
                  packageOption: string;
              };
    },
) {
    const { data } = await axios.post(SERVICE_URL + 'order/employee/create/', {
        accessToken,
        order: {
            menuItems,
            billOption,
        },
    });
    return CreateOrderFromEmployeeResponseSchema.parse(data);
}

export async function callCustomerCheckout(
    isSandbox: boolean,
    squareToken: string,
    restaurantId: string,
    firstName: string,
    lastName: string,
    menuItems: OrderMenuItem[],
) {
    // TODO: delivery
    const { data } = await axios.post(SERVICE_URL + 'customer/checkout/', {
        isSandbox,
        squareToken,
        restaurantId,
        deliveryMethod: {
            type: 'TakeOut',
            data: {
                firstName,
                lastName,
            },
        },
        menuItems,
    });
    return data;
}
