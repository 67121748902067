import { BottomBarModal, Button, ButtonColor } from 'component';
import SelectQuantity from 'component/SelectQuantity/SelectQuantity';
import { cartSlice } from 'state/cartSlice';
import { getMenuItemModsForMenuItem } from 'state/restaurantSlice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { formatPrice } from 'util/PriceUtil';
import SelectModComponent from '../SelectModComponent/SelectModComponent';
import classes from './SelectModModal.module.css';

export default function SelectModModal() {
    const dispatch = useAppDispatch();
    const menuItem = useAppSelector((state) => state.cartData.menuItem);
    const orderMenuItem = useAppSelector((state) => state.cartData.orderMenuItem);
    const price = useAppSelector((state) => state.cartData.price);

    const addToCardClick = () => {
        if (orderMenuItem) {
            dispatch(cartSlice.actions.addCartItem(orderMenuItem));
        }
    }

    if (menuItem == null) return null;
    return (
        <BottomBarModal
            onClose={() => dispatch(cartSlice.actions.clearMenuItem())}
            closeOnClickOutside={true}
            leftContent={[
                <SelectQuantity
                    key="quantity"
                    onQuantityChange={(quantity) => dispatch(cartSlice.actions.setQuantity(quantity))}
                />,
            ]}
            rightContent={[
                <Button
                    key="add"
                    text={`Add to cart: ${formatPrice(price)}`}
                    color={orderMenuItem !== null ? ButtonColor.Default : ButtonColor.Gray}
                    onClick={addToCardClick}
                />,
            ]}
        >
            <div className={classes.ContentContainer}>
                <div className={classes.Title}>{menuItem.name}</div>
                <div className={classes.SubTitle}>{formatPrice(menuItem.price)}</div>
                {getMenuItemModsForMenuItem(menuItem.id).map((mod) => (
                    <SelectModComponent key={mod.id} menuItemMod={mod} />
                ))}
            </div>
        </BottomBarModal>
    );
}
