import { MenuItemMod } from 'types/data-types';
import { ReadByStr } from 'types/util';
import { formatPrice } from 'util/PriceUtil';
import './SelectModComponent.css';

type SelectModComponentProp = {
    menuItemMod: MenuItemMod;
    modSelections: ReadByStr<boolean>;
    onModToggle: (mod: MenuItemMod, choiceId: string) => void;
};
export default function SelectModComponent(props: SelectModComponentProp) {
    const menuItemMod = props.menuItemMod;
    const selectedCount = Object.values(props.modSelections).filter((selected) => selected).length;

    let selectHintText = '';
    if (menuItemMod.minSelect === menuItemMod.maxSelect) {
        selectHintText = `Choose ${menuItemMod.minSelect}`;
    } else if (menuItemMod.maxSelect < menuItemMod.choices.length) {
        selectHintText = `Choose up to ${menuItemMod.maxSelect}`;
    }

    let selectHintColor = undefined;
    let selectedChoiceColor = 'lightgreen';
    if (selectedCount > menuItemMod.maxSelect) {
        selectHintColor = '#ff0000';
        selectedChoiceColor = '#ff5555';
    } else if (menuItemMod.minSelect > 0) {
        selectHintColor = '#ff7700';
    }

    return (
        <div className="SelectModComponentContainer">
            <div className="SelectModComponentTopContainer">
                <div className="SelectModComponentName">{menuItemMod.name}</div>
                <div className="SelectModComponentHint" style={{ color: selectHintColor }}>
                    {selectHintText}
                </div>
            </div>
            <div className="SelectModComponentChoicesContainer">
                {menuItemMod.choices.map((choice) => (
                    <div
                        key={choice.id}
                        className="SelectModComponentChoiceContainer"
                        onClick={() => props.onModToggle(menuItemMod, choice.id)}
                        style={{ backgroundColor: props.modSelections[choice.id] ? selectedChoiceColor : undefined }}
                    >
                        <div className="SelectModComponentChoiceName">{choice.name}</div>
                        {choice.price > 0 && (
                            <div className="SelectModComponentChoicePrice">{formatPrice(choice.price)}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
