import { CenterMaxWidth, MenuItemButton, MenuItemCategoryBar } from 'component';
import { useState } from 'react';
import { useRestaurantData } from 'state/hooks/restaurant';
import { getMenuItems } from 'state/restaurantSlice';
import { MenuItem } from 'types/data-types';
import classes from './SelectMenuItem.module.css';

const menuItemContainerStyle: React.CSSProperties = { overflowY: 'auto' };

type SelectMenuItemProp = {
    initialCategory: string | null;
    onSelectMenuItem: (menuItem: MenuItem) => void;
    onCategorySelect: (category: string) => void;
};
export default function SelectMenuItem(props: SelectMenuItemProp) {
    const categories = useRestaurantData().restaurant.categories;
    const menuItems = Object.values(getMenuItems());
    const [selectedCategory, setSelectedCategory] = useState<string | null>(
        props.initialCategory || categories[0] || null,
    );

    const menuItemsForCategory = menuItems.filter((menuItem) => menuItem.category === selectedCategory);
    const onCategorySelect = (category: string) => {
        props.onCategorySelect(category);
        setSelectedCategory(category);
    };
    return (
        <div className={classes.SelectMenuItemContainer}>
            <MenuItemCategoryBar categories={categories} onSelect={onCategorySelect} />
            <CenterMaxWidth width={1080} style={menuItemContainerStyle}>
                <div className={classes.MenuItemContainer}>
                    {menuItemsForCategory.map((menuItem) => (
                        <MenuItemButton menuItem={menuItem} key={menuItem.id} onClick={props.onSelectMenuItem} />
                    ))}
                </div>
            </CenterMaxWidth>
        </div>
    );
}
