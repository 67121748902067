import { IconButton, IconButtonIcon } from 'component';
import { useState } from 'react';
import classes from './SelectQuantity.module.css';

type SelectQuantityProp = {
    onQuantityChange: (quantity: number) => void;
};
export default function SelectQuantity(props: SelectQuantityProp) {
    const [quantity, setQuantity] = useState(1);

    const changeQuantity = (incrase: boolean) => {
        const newQuantity = Math.max(1, Math.min(1000, incrase ? quantity + 1 : quantity - 1));
        setQuantity(newQuantity);
        props.onQuantityChange(newQuantity);
    };
    return (
        <div className={classes.SelectQuantityContainer}>
            <IconButton icon={IconButtonIcon.Minus} onClick={() => changeQuantity(false)} />
            <div className={classes.SelectQuantityText}>{quantity}</div>
            <IconButton icon={IconButtonIcon.Plus} onClick={() => changeQuantity(true)} />
        </div>
    );
}
