import { HeaderBarButton } from 'component';
import { useState } from 'react';
import bill from 'res/bill.png';
import delivery from 'res/delivery.png';
import home from 'res/home.png';
import takeout from 'res/takeout.png';
import { useAppDispatch } from 'state/store';
import { userDataSlice } from 'state/userSlice';
import NewDeliveryModal from '../NewDeliveryModal/NewDeliveryModal';
import NewTakeoutModal from '../NewTakeoutModal/NewTakeoutModal';
import './HeaderBar.css';

type HeaderBarProps = {
    homeClick: () => void;
    billClick: () => void;
};

export default function HeaderBar({ homeClick, billClick }: HeaderBarProps) {
    const dispatch = useAppDispatch();
    const [newDeliveryOpen, setNewDeliveryOpen] = useState(false);
    const [newTakeoutOpen, setNewTakeoutOpen] = useState(false);
    return (
        <div className="HeaderBar">
            <div className="HeaderBar-ButtonContainer">
                <img className="HeaderBarIconButton" src={home} alt="home" onClick={homeClick} />
                <img className="HeaderBarIconButton" src={bill} alt="bill" onClick={billClick} />
            </div>
            <div className="HeaderBar-ButtonContainer">
                <img
                    className="HeaderBarIconButton"
                    src={delivery}
                    alt="delivery"
                    onClick={() => setNewDeliveryOpen(true)}
                />
                <img
                    className="HeaderBarIconButton"
                    src={takeout}
                    alt="takeout"
                    onClick={() => setNewTakeoutOpen(true)}
                />
                <HeaderBarButton text={'Signout'} onClick={() => dispatch(userDataSlice.actions.signOut())} />
            </div>
            {newDeliveryOpen && <NewDeliveryModal onClose={() => setNewDeliveryOpen(false)} />}
            {newTakeoutOpen && <NewTakeoutModal onClose={() => setNewTakeoutOpen(false)} />}
        </div>
    );
}
