import c from './CenterMaxWidth.module.css';

type CenterMaxWidthProp = {
    width: number;
    style?: React.CSSProperties;
    children: JSX.Element;
};
export default function CenterMaxWidth(props: CenterMaxWidthProp) {
    const { width, style, children } = props;

    return (
        <div className={c.CenterMaxWidthContainer} style={style}>
            <div className={c.CenterMaxWidth} style={{ maxWidth: width }}>
                {children}
            </div>
        </div>
    );
}
