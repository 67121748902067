import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type UserDataType = {
    accessToken: string | null;
};

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        accessToken: null,
    } as UserDataType,
    reducers: {
        signInSuccess: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        signOut: (state, action: PayloadAction<void>) => {
            state.accessToken = null;
        },
    },
});

export default userDataSlice.reducer;
