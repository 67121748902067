import { z } from 'zod';
import { DeepReadonly } from './util';

export const RestaurantSchema = z.object({
    id: z.string(),
    ownerId: z.string(),
    name: z.string(),
    address: z.string(),
    categories: z.array(z.string()),
    createdAt: z.number().int(),
});
export type Restaurant = DeepReadonly<z.infer<typeof RestaurantSchema>>;

export const MenuItemSchema = z.object({
    id: z.string(),
    restaurantId: z.string(),
    category: z.string(),
    name: z.string(),
    description: z.string(),
    price: z.number().int(),
    mods: z.array(z.string()),
    imageUrl: z.string().nullable(),
    createdAt: z.number().int(),
});
export type MenuItem = DeepReadonly<z.infer<typeof MenuItemSchema>>;

export const MenuItemModChoiceSchema = z.object({
    id: z.string(),
    name: z.string(),
    price: z.number().gte(0).int(),
});
export type MenuItemModChoice = DeepReadonly<z.infer<typeof MenuItemModChoiceSchema>>;
export const MenuItemModSchema = z.object({
    id: z.string(),
    restaurantId: z.string(),
    name: z.string(),
    description: z.string(),
    minSelect: z.number().gte(0).int(),
    maxSelect: z.number().gte(1).int(),
    choices: z.array(MenuItemModChoiceSchema).nonempty(),
    createdAt: z.number().int(),
});
export type MenuItemMod = DeepReadonly<z.infer<typeof MenuItemModSchema>>;

export const OrderMenuItemSchema = z.object({
    menuItemId: z.string(),
    mods: z.record(z.array(z.string())),
    quantity: z.number().gte(1).int(),
    price: z.number().int(),
    note: z.string(),
});
export type OrderMenuItem = DeepReadonly<z.infer<typeof OrderMenuItemSchema>>;
export const PackageOptionSchema = z.enum(['DineIn', 'ToGo']);
export const OrderStepSchema = z.enum(['Created', 'Prepared', 'Delivered']);
export const OrderSchema = z.object({
    id: z.string(),
    restaurantId: z.string(),
    billId: z.string(),
    packageOption: PackageOptionSchema,
    menuItems: z.array(OrderMenuItemSchema).nonempty(),
    history: z.record(OrderStepSchema, z.number().int()),
});
export type Order = DeepReadonly<z.infer<typeof OrderSchema>>;

export const DeliveryMethodType = z.enum(['DineIn', 'TakeOut', 'Delivery']);
export const DeliveryMethodSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(DeliveryMethodType.Enum.DineIn),
        data: z.object({
            table: z.string(),
        }),
    }),
    z.object({
        type: z.literal(DeliveryMethodType.Enum.TakeOut),
        data: z.object({
            firstName: z.string(),
            lastName: z.string(),
        }),
    }),
    z.object({
        type: z.literal(DeliveryMethodType.Enum.Delivery),
        data: z.object({
            firstName: z.string(),
            lastName: z.string(),
            address: z.string(),
        }),
    }),
]);
export type DeliveryMethod = DeepReadonly<z.infer<typeof DeliveryMethodSchema>>;
export const BillStepSchema = z.enum(['Created', 'Accepted', 'Delivered']);
export const BillSchema = z.object({
    id: z.string(),
    restaurantId: z.string(),
    deliveryMethod: DeliveryMethodSchema,
    orders: z.array(z.string()).nonempty(),
    history: z.record(BillStepSchema, z.number().int()),
});
export type Bill = DeepReadonly<z.infer<typeof BillSchema>>;
