import { useState } from 'react';
import { Button } from 'component';
import { initializeAfterSignin } from 'state/actions/actions';
import { callSignIn } from 'state/api';
import { useApiCall } from 'state/hooks/hooks';
import { useAppDispatch } from 'state/store';
import './SignInPage.css';

export default function SignInPage() {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState('user1');
    const [password, setPassword] = useState('pass1');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [signInCall, isInProgress] = useApiCall(callSignIn);

    const signIn = async () => {
        setErrorMessage(null);
        signInCall(username, password)
            ?.then((response) => {
                if (response.success) {
                    dispatch(initializeAfterSignin(response.data.accessToken));
                } else {
                    setErrorMessage(response.message);
                }
            })
            .catch((error) => setErrorMessage('Please try again later.'));
    };

    const handleKeyDown = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            signIn();
        }
    };

    return (
        <div className="SignInPage">
            <input
                className="SignInPage-UserNameInput"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                className="SignInPage-PasswordInput"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <div className="SignInPage-ErrorMessage">{errorMessage}</div>
            <Button text={'Sign In'} onClick={signIn} disabled={isInProgress} inProgress={isInProgress} />
        </div>
    );
}
