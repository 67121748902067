import { OrderMenuItem } from 'types/data-types';
import { buildMenuItemsSummary } from 'util/OrderUtil';
import { Button, IndentedText }  from 'component';
import './CreateOrderStatus.css';

type CreateOrderStatusProp = {
    orderMenuItems: OrderMenuItem[];
    inProgress: boolean;
    disabled: boolean;
    error: string | null;
    createOrder: () => void;
};

export default function CreateOrderStatus(props: CreateOrderStatusProp) {
    const { orderMenuItems, inProgress, disabled, error, createOrder } = props;

    return (
        <div className="CreateOrderStatusContainer">
            <div className="CreateOrderSummaryContainer">
                <IndentedText value={buildMenuItemsSummary(orderMenuItems)} />
            </div>
            <div className="CreateOrderStatus-ErrorMessage">{error}</div>
            {orderMenuItems.length > 0 && (
                <Button text={'Done'} onClick={createOrder} inProgress={inProgress} disabled={disabled} />
            )}
        </div>
    );
}
