import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataState, GetRestaurantMenuResponse } from 'types/api-types';
import { MenuItem, MenuItemMod, MenuItemModChoice, Restaurant } from 'types/data-types';
import { DeepNotReadonly, ReadByStr, mapById } from 'types/util';

export const UNKNOWN_MENU_ITEM: MenuItem = {
    id: 'unknown_menu_item',
    name: 'Unknown',
    createdAt: 0,
    restaurantId: 'unknown_restaurant',
    description: 'Unknown',
    price: 0,
    imageUrl: null,
    category: 'unknown',
    mods: [],
};
export const UNKNOWN_MENU_ITEM_MOD_CHOICE: MenuItemModChoice = {
    id: 'unknown_menu_item_mod_choice',
    name: 'Unknown',
    price: 0,
};
export const UNKNOWN_MENU_ITEM_MOD: MenuItemMod = {
    id: 'unknown_menu_item_mod',
    name: 'Unknown',
    createdAt: 0,
    restaurantId: 'unknown_restaurant',
    description: 'Unknown',
    minSelect: 0,
    maxSelect: 0,
    choices: [UNKNOWN_MENU_ITEM_MOD_CHOICE],
};

type RestaurantDataLoading = {
    type: DataState.Loading;
};

export type RestaurantDataLoaded = {
    type: DataState.Loaded;
    restaurant: Restaurant;
    menuItems: ReadByStr<MenuItem>;
    menuItemMods: ReadByStr<MenuItemMod>;
};

type RestaurantDataError = {
    type: DataState.Error;
    error: string;
};

type RestaurantDataType = RestaurantDataLoading | RestaurantDataLoaded | RestaurantDataError;

let menuItemsCache: ReadByStr<MenuItem> = {};
let menuItemModsCache: ReadByStr<MenuItemMod> = {};
export const restaurantDataSlice = createSlice({
    name: 'restaurantData',
    initialState: {
        data: {
            type: DataState.Loading,
        } as RestaurantDataType,
    },
    reducers: {
        onRestaurantDataLoaded: (state, action: PayloadAction<GetRestaurantMenuResponse>) => {
            const payload = action.payload;
            const restaurant = payload.restaurant as DeepNotReadonly<Restaurant>;
            const menuItems = mapById(payload.menuItems as DeepNotReadonly<MenuItem[]>);
            const menuItemMods = mapById(payload.menuItemMods as DeepNotReadonly<MenuItemMod[]>);
            state.data = {
                type: DataState.Loaded,
                restaurant,
                menuItems,
                menuItemMods,
            };
            menuItemsCache = menuItems;
            menuItemModsCache = menuItemMods;
        },
        onRestaurantDataError: (state, action: PayloadAction<string>) => {
            state.data = {
                type: DataState.Error,
                error: action.payload,
            };
            menuItemsCache = {};
            menuItemModsCache = {};
        },
        clearRestaurantData: (state) => {
            state.data = {
                type: DataState.Loading,
            };
            menuItemsCache = {};
            menuItemModsCache = {};
        },
    },
});

export const getMenuItems = () => menuItemsCache;
export const getMenuItem = (id: string) => menuItemsCache[id] || UNKNOWN_MENU_ITEM;

export const getMenuItemMods = () => menuItemModsCache;
export const getMenuItemMod = (id: string) => menuItemModsCache[id] || UNKNOWN_MENU_ITEM_MOD;
export const getMenuItemModsForMenuItem = (id: string) => getMenuItem(id).mods.map((modId) => getMenuItemMod(modId));
export const getMenuItemModChoice = (modId: string, choiceId: string) =>
    getMenuItemMod(modId).choices.find((choice) => choice.id === choiceId) || UNKNOWN_MENU_ITEM_MOD_CHOICE;

export default restaurantDataSlice.reducer;
