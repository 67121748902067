import minus from 'res/minus.png';
import plus from 'res/plus.png';
import './IconButton.css';

export enum IconButtonIcon {
    Plus = 'Plus',
    Minus = 'Minus',
}

type IconButtonProp = {
    icon: IconButtonIcon;
    onClick?: () => void;
};

const getImageForIcon = (icon: IconButtonIcon) => {
    switch (icon) {
        case IconButtonIcon.Plus:
            return plus;
        case IconButtonIcon.Minus:
            return minus;
        default:
            return undefined;
    }
};

export default function IconButton(props: IconButtonProp) {
    return (
        <div className="IconButtonContainer" onClick={props.onClick}>
            <img className="IconButtonImage" src={getImageForIcon(props.icon)} alt="icon" />
        </div>
    );
}
