import Button, { ButtonColor } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

type LinkButtonProp = {
    text: string;
    to: string;
    color?: ButtonColor;
    disabled?: boolean;
};

export default function LinkButton(props: LinkButtonProp) {
    const navigate = useNavigate();
    const { text, color, disabled, to } = props;

    return <Button text={text} color={color} disabled={disabled} onClick={() => navigate(to)}></Button>;
}
