import { IndentedTextInput } from '../component/IndentedText/IndentedText';
import {
    UNKNOWN_MENU_ITEM,
    UNKNOWN_MENU_ITEM_MOD,
    getMenuItem,
    getMenuItemMod,
    getMenuItemModChoice,
} from '../state/restaurantSlice';
import { Order, OrderMenuItem, OrderMenuItemSchema } from 'types/data-types';
import { ReadByStr } from 'types/util';

export function buildOrdersSummary(orders: readonly Order[]): IndentedTextInput {
    if (orders.length === 1) {
        return buildMenuItemsSummary(orders[0].menuItems);
    }
    const result: IndentedTextInput = [];
    orders.forEach((order, index) => {
        result.push(`Order #${index + 1}`);
        result.push(buildMenuItemsSummary(order.menuItems));
    });
    return result;
}

export function buildMenuItemsSummary(menuItems: readonly OrderMenuItem[]): IndentedTextInput {
    const result: IndentedTextInput = [];
    menuItems.forEach((item) => {
        const menuItem = getMenuItem(item.menuItemId);
        result.push(`${item.quantity}x ${menuItem.name}`);

        const modsText: IndentedTextInput = [];
        Object.entries(item.mods).forEach(([modId, choices]) => {
            if (choices.length > 0) {
                const modContent = choices.map((choiceId) => getMenuItemModChoice(modId, choiceId).name).join(', ');
                modsText.push(`${getMenuItemMod(modId).name}: ${modContent}`);
            }
        });
        result.push(modsText);
    });
    return result;
}

export function validateAddMenuItemToOrder(
    menuItemId: string,
    quantity: number,
    modSelections: ReadByStr<string[]>,
): OrderMenuItem | null {
    const menuItem = getMenuItem(menuItemId);
    if (menuItem === UNKNOWN_MENU_ITEM) {
        return null;
    }

    let price = menuItem.price;
    const mods: { [id: string]: string[] } = {};
    for (let i = 0; i < menuItem.mods.length; i++) {
        const mod = getMenuItemMod(menuItem.mods[i]);
        if (mod === UNKNOWN_MENU_ITEM_MOD) {
            return null;
        }

        const selection = modSelections[mod.id];
        if (!selection) {
            return null;
        }

        const selectedChoices: string[] = [];
        for (let j = 0; j < mod.choices.length; j++) {
            const choice = mod.choices[j];
            if (selection.includes(choice.id)) {
                selectedChoices.push(choice.id);
                price += choice.price;
            }
        }

        if (!(mod.minSelect <= selectedChoices.length && selectedChoices.length <= mod.maxSelect)) {
            return null;
        }
        mods[mod.id] = selectedChoices;
    }

    const result = OrderMenuItemSchema.safeParse({
        menuItemId,
        quantity,
        mods,
        price: price * quantity,
        note: '',
    });
    if (!result.success) {
        return null;
    }
    return result.data;
}
