import Modal from '../Modal/Modal';
import classes from './BottomBarModal.module.css';

type BottomBarModalProps = {
    onClose: () => void;
    closeOnClickOutside?: boolean;
    children: JSX.Element;
    leftContent?: JSX.Element[];
    rightContent?: JSX.Element[];
};

export default function BottomBarModal(props: BottomBarModalProps) {
    return (
        <Modal onClose={props.onClose} closeOnClickOutside={props.closeOnClickOutside}>
            <div className={classes.BottomBarModalContainer}>
                <div className={classes.BottomBarModalContent}>{props.children}</div>
                <div className={classes.BottomBarModalButtonContainers}>
                    <div className={classes.BottomBarModalButtonContainer}>{props.leftContent}</div>
                    <div className={classes.BottomBarModalButtonContainer}>{props.rightContent}</div>
                </div>
            </div>
        </Modal>
    );
}
