import { useState } from 'react';
import cart from 'res/cart.png';
import { useAppSelector } from 'state/store';
import CartModal from '../CartModal/CartModal';
import c from './CartButton.module.css';

export default function CartButton() {
    const cartItems = useAppSelector((state) => state.cartData.cartItems);
    const [modalOpen, setModalOpen] = useState(false);
    const onClick = () => {
        setModalOpen(!modalOpen);
    };

    if (cartItems.length === 0) return null;
    return (
        <>
            <div className={c.CartButton}>
                <img className={c.CartButtonImage} src={cart} alt="cart" onClick={onClick} />
                <div className={c.CartButtonCount}>{cartItems.length}</div>
                {modalOpen && <CartModal />}
            </div>
            {modalOpen && <div className={c.CartModalBackground} onClick={() => setModalOpen(false)} />}
        </>
    );
}
