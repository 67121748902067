import { DataState } from 'types/api-types';
import { Bill, Order } from 'types/data-types';
import { ReadByStr } from 'types/util';
import { CurrentOrderCreation, OrderDataLoaded } from '../orderSlice';
import { useAppSelector } from '../store';

export function useOrderData(): OrderDataLoaded {
    const state = useAppSelector((state) => state.orderData.data);
    if (state.type !== DataState.Loaded) {
        throw Error('Invalid order data state.');
    }
    return state;
}

export function useBills(): ReadByStr<Bill> {
    return useOrderData().bills;
}

export function useOrders(): ReadByStr<Order> {
    return useOrderData().orders;
}

export function useOrdersForBill(bill: Bill | undefined): Order[] {
    const allOrders = useOrders();
    if (!bill) {
        return [];
    }
    return bill.orders.map((orderId) => {
        if (!(orderId in allOrders)) {
            throw Error('Invalid orderId');
        }
        return allOrders[orderId];
    });
}

export function useCurrentOrderCreation(): CurrentOrderCreation {
    const orderData = useOrderData();
    if (orderData.currentOrderCreation === null) {
        throw Error('Invalid order creation state.');
    }
    return orderData.currentOrderCreation;
}
