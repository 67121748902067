import classes from './HeaderBar.module.css';

type HeaderBarProps = {
    childrenLeft?: JSX.Element[];
    childrenRight?: JSX.Element[];
};

export default function HeaderBar({ childrenLeft, childrenRight }: HeaderBarProps) {
    return (
        <div className={classes.HeaderBar}>
            <div className={classes.HeaderBarContentContainer}>{childrenLeft}</div>
            <div className={classes.HeaderBarContentContainer}>{childrenRight}</div>
        </div>
    );
}
