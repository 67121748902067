import './Modal.css';

type ModalProps = {
    closeOnClickOutside?: boolean;
    children: JSX.Element;
    onClose: () => void;
};

export default function Modal(props: ModalProps) {
    const { closeOnClickOutside, children, onClose } = props;
    return (
        <div className="ModalContainer" onClick={closeOnClickOutside ? onClose : undefined}>
            <div className="Modal" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
}
