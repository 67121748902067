import { useBills, useOrdersForBill } from 'state/hooks/order';
import { Bill, DeliveryMethodType } from 'types/data-types';
import { buildOrdersSummary } from 'util/OrderUtil';
import { IndentedText } from 'component';
import './BillList.css';

function BillComponent({ bill }: { bill: Bill }): JSX.Element | null {
    const orders = useOrdersForBill(bill);
    let headerText;
    if (bill.deliveryMethod.type === DeliveryMethodType.Enum.DineIn) {
        headerText = `Table ${bill.deliveryMethod.data.table}`;
    } else if (bill.deliveryMethod.type === DeliveryMethodType.Enum.Delivery) {
        const { firstName, lastName } = bill.deliveryMethod.data;
        headerText = `Delivery: ${firstName} ${lastName}`;
    } else if (bill.deliveryMethod.type === DeliveryMethodType.Enum.TakeOut) {
        const { firstName, lastName } = bill.deliveryMethod.data;
        headerText = `PickUp: ${firstName} ${lastName}`;
    }

    return (
        <div className="BillListContainerBillContainer">
            <div className="BillListBillHeader">{headerText}</div>
            <div className="BillListBillSummaryContainer">
                <IndentedText value={buildOrdersSummary(orders)} />
            </div>
        </div>
    );
}

export default function BillList() {
    const bills = Object.values(useBills());

    return (
        <div className="BillListContainer">
            {bills.map((bill) => (
                <BillComponent key={bill.id} bill={bill} />
            ))}
        </div>
    );
}
