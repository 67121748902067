import food from 'res/food.png';
import { MenuItem } from 'types/data-types';
import './MenuItemButton.css';

type MenuItemButtonProp = {
    menuItem: MenuItem;
    onClick: (menuItem: MenuItem) => void;
};
export default function MenuItemButton(props: MenuItemButtonProp) {
    const image = props.menuItem.imageUrl || food;
    return (
        <div className="MenuItemButtonContainer" onClick={() => props.onClick(props.menuItem)}>
            <img className="MenuItemImage" src={image} alt={props.menuItem.name} />
            <div className="MenuItemName">{props.menuItem.name}</div>
        </div>
    );
}
