import { SelectMenuItem, Sidebar } from 'component';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { callCreateOrderFromEmployee } from 'state/api';
import { useApiCall } from 'state/hooks/hooks';
import { useCurrentOrderCreation } from 'state/hooks/order';
import { useRestaurantData } from 'state/hooks/restaurant';
import { useAccessToken } from 'state/hooks/user';
import { orderDataSlice } from 'state/orderSlice';
import { DeliveryMethodType, MenuItem, OrderMenuItem, PackageOptionSchema } from 'types/data-types';
import { CreateOrderStatus, SelectMenuItemMod } from '../../component';
import './AddOrderPage.css';

export default function AddOrderPage() {
    const accessToken = useAccessToken();
    const restaurantData = useRestaurantData();
    const currentOrderCreation = useCurrentOrderCreation();
    const dispatch = useDispatch();

    const [initialCategory, setInitialCategory] = useState<string | null>(null);
    const [orderMenuItems, setOrderMenuItems] = useState<OrderMenuItem[]>([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null);
    const [createOrderCall, inProgress] = useApiCall(callCreateOrderFromEmployee);
    const [createOrderError, setCreateOrderError] = useState<string | null>(null);

    const selectMenuItem = (menuItem: MenuItem | null) => {
        if (inProgress) return;
        setSelectedMenuItem(menuItem);
    };

    const addOrderMenuItem = (orderMenuItem: OrderMenuItem) => {
        setOrderMenuItems([...orderMenuItems, orderMenuItem]);
        setSelectedMenuItem(null);
    };

    const createOrder = () => {
        const billOption =
            currentOrderCreation.existingBill &&
            currentOrderCreation.deliveryMethod.type === DeliveryMethodType.Enum.DineIn
                ? {
                      type: 'Existing',
                      data: {
                          billId: currentOrderCreation.existingBill,
                          packageOption: PackageOptionSchema.Enum.DineIn,
                      },
                  }
                : {
                      type: 'New',
                      data: {
                          restaurantId: restaurantData.restaurant.id,
                          deliveryMethod: currentOrderCreation.deliveryMethod,
                      },
                  };
        createOrderCall(accessToken, orderMenuItems, billOption)
            ?.then(({ bill, order }) => {
                dispatch(orderDataSlice.actions.onOrderCreationComplete({ bill, order }));
            })
            .catch((error) => {
                setCreateOrderError('Something went wrong. Please try again.');
            });
    };

    let content;
    if (selectedMenuItem) {
        content = (
            <SelectMenuItemMod
                menuItem={selectedMenuItem}
                addOrderMenuItem={addOrderMenuItem}
                backClick={() => selectMenuItem(null)}
            />
        );
    } else {
        content = (
            <SelectMenuItem
                initialCategory={initialCategory}
                onCategorySelect={setInitialCategory}
                onSelectMenuItem={selectMenuItem}
            />
        );
    }
    return (
        <div className="AddOrderPageContainer">
            {content}
            <Sidebar>
                <CreateOrderStatus
                    orderMenuItems={orderMenuItems}
                    createOrder={createOrder}
                    inProgress={inProgress}
                    disabled={selectedMenuItem !== null || inProgress}
                    error={createOrderError}
                />
            </Sidebar>
        </div>
    );
}
