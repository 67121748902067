import cloneDeep from 'lodash.clonedeep';

export type DeepReadonly<T> = {
    readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type DeepNotReadonly<T> = {
    -readonly [P in keyof T]: DeepNotReadonly<T[P]>;
};

export type ByStr<T> = { [id: string]: T };

export type ReadByStr<T> = DeepReadonly<{ [id: string]: T }>;

export function mapById<T extends { id: string }>(items: T[]): ByStr<T> {
    return Object.fromEntries(items.map((item) => [item.id, item]));
}

export function clone<T>(obj: T): DeepNotReadonly<T> {
    return cloneDeep(obj);
}
