import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataState } from 'types/api-types';
import { Bill, DeliveryMethod, DeliveryMethodType, Order, PackageOptionSchema } from 'types/data-types';
import { DeepNotReadonly, DeepReadonly, ReadByStr, mapById } from 'types/util';

type OrderDataLoading = {
    type: DataState.Loading;
};

export type CurrentOrderCreation = DeepReadonly<{
    deliveryMethod: DeliveryMethod;
    packageOption: string;
    existingBill: string | null;
}>;

export type OrderDataLoaded = {
    type: DataState.Loaded;
    bills: ReadByStr<Bill>;
    orders: ReadByStr<Order>;
    currentOrderCreation: CurrentOrderCreation | null;
};

type OrderDataError = {
    type: DataState.Error;
    error: string;
};

type OrderDataType = OrderDataLoading | OrderDataLoaded | OrderDataError;

export const orderDataSlice = createSlice({
    name: 'orderData',
    initialState: {
        data: {
            type: DataState.Loading,
        } as OrderDataType,
    },
    reducers: {
        onOrderDataLoaded: (state, action: PayloadAction<{ bills: Bill[]; orders: Order[] }>) => {
            state.data = {
                type: DataState.Loaded,
                bills: mapById(action.payload.bills as DeepNotReadonly<Bill[]>),
                orders: mapById(action.payload.orders as DeepNotReadonly<Order[]>),
                currentOrderCreation: null,
            };
        },
        onOrderDataError: (state, action: PayloadAction<string>) => {
            state.data = {
                type: DataState.Error,
                error: action.payload,
            };
        },
        startTableOrder: (state, action: PayloadAction<{ tableId: string; billId: string | null }>) => {
            assertOrderDataLoaded(state.data);
            state.data.currentOrderCreation = {
                deliveryMethod: {
                    type: DeliveryMethodType.Enum.DineIn,
                    data: {
                        table: action.payload.tableId,
                    },
                },
                packageOption: PackageOptionSchema.Enum.DineIn,
                existingBill: action.payload.billId,
            };
        },
        startDeliveryOrder: (
            state,
            action: PayloadAction<{ firstName: string; lastName: string; address: string }>,
        ) => {
            assertOrderDataLoaded(state.data);
            state.data.currentOrderCreation = {
                deliveryMethod: {
                    type: DeliveryMethodType.Enum.Delivery,
                    data: {
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        address: action.payload.address,
                    },
                },
                packageOption: PackageOptionSchema.Enum.ToGo,
                existingBill: null,
            };
        },
        startTakeoutOrder: (state, action: PayloadAction<{ firstName: string; lastName: string }>) => {
            assertOrderDataLoaded(state.data);
            state.data.currentOrderCreation = {
                deliveryMethod: {
                    type: DeliveryMethodType.Enum.TakeOut,
                    data: {
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                    },
                },
                packageOption: PackageOptionSchema.Enum.ToGo,
                existingBill: null,
            };
        },
        clearOrderCreation: (state, action: PayloadAction<void>) => {
            assertOrderDataLoaded(state.data);
            state.data.currentOrderCreation = null;
        },
        onOrderCreationComplete: (state, action: PayloadAction<{ bill: Bill; order: Order }>) => {
            assertOrderDataLoaded(state.data);
            const { bill, order } = action.payload;
            state.data = {
                ...state.data,
                bills: {
                    ...state.data.bills,
                    [bill.id]: bill as DeepNotReadonly<Bill>,
                },
                orders: {
                    ...state.data.orders,
                    [order.id]: order as DeepNotReadonly<Order>,
                },
                currentOrderCreation: null,
            };
        },
    },
});

function assertOrderDataLoaded(state: OrderDataType): asserts state is OrderDataLoaded {
    if (state.type !== DataState.Loaded) {
        throw Error('Invalid order data state.');
    }
}

export default orderDataSlice.reducer;
