import { getMenuItemModChoice } from 'state/restaurantSlice';
import { MenuItem } from 'types/data-types';
import { ReadByStr } from 'types/util';

export const getMenuItemSelectionPrice = (
    menuItem: MenuItem,
    quantity: number,
    modSelections: ReadByStr<string[]>,
): number => {
    let price = menuItem.price;
    Object.entries(modSelections).forEach(([modId, selectedChoices]) => {
        selectedChoices.forEach((choiceId) => {
            price += getMenuItemModChoice(modId, choiceId).price;
        });
    });
    return price * quantity;
};

export const formatPrice = (price: number): string => {
    return `$${(price / 100).toFixed(2)}`;
};
