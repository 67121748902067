import { Button, SelectQuantity } from 'component';
import { useCallback, useMemo, useState } from 'react';
import { getMenuItemModsForMenuItem } from 'state/restaurantSlice';
import { MenuItem, MenuItemMod, OrderMenuItem } from 'types/data-types';
import { ByStr, ReadByStr } from 'types/util';
import { validateAddMenuItemToOrder } from 'util/OrderUtil';
import { formatPrice, getMenuItemSelectionPrice } from 'util/PriceUtil';
import back from 'res/back.png';
import './SelectMenuItemMod.css';
import SelectModComponent from './SelectModComponent';

type MenuItemModSelection = ReadByStr<ReadByStr<boolean>>;
const updateChoiceSelection = (
    mod: MenuItemMod,
    modSelections: ReadByStr<boolean>,
    choiceId: string,
): ByStr<boolean> => {
    if (mod.maxSelect === 1) {
        const newSelection = Object.fromEntries(mod.choices.map((choice) => [choice.id, false]));
        newSelection[choiceId] = true;
        return newSelection;
    }
    const newSelection = { ...modSelections };
    newSelection[choiceId] = !newSelection[choiceId];
    return newSelection;
};

const getSelectedChoiceByModId = (selection: MenuItemModSelection): ByStr<string[]> => {
    return Object.fromEntries(
        Object.entries(selection).map(([modId, choices]) => {
            const selectedChoices = Object.entries(choices)
                .filter((entry) => entry[1])
                .map((entry) => entry[0]);
            return [modId, selectedChoices];
        }),
    );
};

type SelectMenuItemModProp = {
    menuItem: MenuItem;
    addOrderMenuItem: (orderMenuItem: OrderMenuItem) => void;
    backClick: () => void;
};
export default function SelectMenuItemMod(props: SelectMenuItemModProp) {
    const { menuItem, addOrderMenuItem, backClick } = props;
    const menuItemMods = getMenuItemModsForMenuItem(menuItem.id);

    const [quantity, setQuantity] = useState(1);
    const [modSelections, setModSelections] = useState<MenuItemModSelection>(
        Object.fromEntries(
            menuItemMods.map((mod) => {
                const selection = Object.fromEntries(mod.choices.map((choice) => [choice.id, false]));
                return [mod.id, selection];
            }),
        ),
    );
    const orderMenuItem = useMemo(() => {
        return validateAddMenuItemToOrder(menuItem.id, quantity, getSelectedChoiceByModId(modSelections));
    }, [menuItem, quantity, modSelections]);

    const onModToggle = useCallback(
        (mod: MenuItemMod, choiceId: string) => {
            setModSelections({
                ...modSelections,
                [mod.id]: updateChoiceSelection(mod, modSelections[mod.id], choiceId),
            });
        },
        [modSelections, setModSelections],
    );
    const onDonePress = useCallback(() => {
        if (!orderMenuItem) {
            return;
        }
        addOrderMenuItem(orderMenuItem);
    }, [orderMenuItem, addOrderMenuItem]);

    const itemPrice = getMenuItemSelectionPrice(menuItem, quantity, getSelectedChoiceByModId(modSelections));

    const modListComponents: JSX.Element[] = [];
    menuItemMods.forEach((mod, index) => {
        modListComponents.push(
            <SelectModComponent
                key={mod.id}
                menuItemMod={mod}
                modSelections={modSelections[mod.id]}
                onModToggle={onModToggle}
            />,
        );
        if (index < menuItemMods.length - 1) {
            modListComponents.push(<div key={index} className="ModSeparator" />);
        }
    });

    return (
        <div className="SelectMenuItemModContainer">
            <div className="SelectMenuItemModTopBar">
                <div className="SelectMenuItemModTopBarGroup">
                    <img className="SelectMenuItemModTopBarBackIcon" src={back} alt="back" onClick={backClick} />
                    <div>{menuItem.name}</div>
                    <SelectQuantity onQuantityChange={(quantity) => setQuantity(quantity)} />
                </div>
                <div className="SelectMenuItemModTopBarGroup">
                    <div className="SelectMenuItemModPrice">{formatPrice(itemPrice)}</div>
                    <Button text="Done" onClick={onDonePress} disabled={orderMenuItem === null} />
                </div>
            </div>
            <div className="SelectMenuItemModContent">{modListComponents}</div>
        </div>
    );
}
