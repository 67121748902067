import { BottomBarModal, Button, IndentedText } from 'component';
import { useBills, useOrdersForBill } from 'state/hooks/order';
import { orderDataSlice } from 'state/orderSlice';
import { useAppDispatch } from 'state/store';
import { DeliveryMethodType } from 'types/data-types';
import { buildOrdersSummary } from 'util/OrderUtil';
import './TableInfoModal.css';

export default function TableInfoModal({ tableId, onClose }: { tableId: string; onClose: () => void }) {
    const dispatch = useAppDispatch();
    const bills = useBills();

    const bill = Object.values(bills).find(
        (bill) =>
            bill.deliveryMethod.type === DeliveryMethodType.Enum.DineIn && bill.deliveryMethod.data.table === tableId,
    );
    const orders = useOrdersForBill(bill);

    const rightButtons = [];
    if (bill) {
        rightButtons.push(
            <Button
                key={'addItem'}
                text={'Add Items'}
                onClick={() => dispatch(orderDataSlice.actions.startTableOrder({ tableId, billId: bill.id }))}
            />,
        );
        rightButtons.push(<Button key={'checkOut'} text={'Check Out'} onClick={() => console.log('asd')} />);
    } else {
        rightButtons.push(
            <Button
                key={'newOrder'}
                text={'New Order'}
                onClick={() => dispatch(orderDataSlice.actions.startTableOrder({ tableId, billId: null }))}
            />,
        );
    }

    const leftButtons = [<Button key="cancel" text={'Cancel'} onClick={onClose} />];
    return (
        <BottomBarModal
            onClose={onClose}
            closeOnClickOutside={true}
            leftContent={leftButtons}
            rightContent={rightButtons}
        >
            <div className="TableInfoModalContent">
                <div className="TableInfoModalContentHeader">{`Table ${tableId}`}</div>
                <IndentedText value={buildOrdersSummary(orders)} />
            </div>
        </BottomBarModal>
    );
}
