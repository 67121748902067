import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MenuItem, MenuItemMod, OrderMenuItem, OrderMenuItemSchema } from 'types/data-types';
import { DeepNotReadonly, ReadByStr, clone } from 'types/util';
import {
    UNKNOWN_MENU_ITEM,
    UNKNOWN_MENU_ITEM_MOD,
    getMenuItem,
    getMenuItemMod,
    getMenuItemModsForMenuItem,
} from './restaurantSlice';

type State = {
    restaurantId: string | null;
    menuItem: MenuItem | null;
    quantity: number;
    modSelections: ReadByStr<ReadByStr<boolean>>;
    orderMenuItem: OrderMenuItem | null;
    price: number;
    cartItems: OrderMenuItem[]
};

const initialState: State = {
    restaurantId: null,
    menuItem: null,
    quantity: 1,
    modSelections: {},
    orderMenuItem: null,
    price: 0,
    cartItems: []
};
export const cartSlice = createSlice({
    name: 'cartData',
    initialState,
    reducers: {
        setMenuItem: (state, action: PayloadAction<MenuItem>) => {
            const menuItem = action.payload;
            state.restaurantId = menuItem.restaurantId;
            // TODO: handle selecting menu from differnt restaurant
            state.menuItem = clone(menuItem);
            state.quantity = 1;

            const mods = getMenuItemModsForMenuItem(menuItem.id);
            state.modSelections = Object.fromEntries(
                mods.map((mod) => {
                    const selection = Object.fromEntries(mod.choices.map((choice) => [choice.id, false]));
                    return [mod.id, selection];
                }),
            );
            const { orderMenuItem, price } = buildOrderMenuItem(state.menuItem.id, state.quantity, state.modSelections);
            state.orderMenuItem = orderMenuItem;
            state.price = price;
        },
        toggleModChoice: (state, action: PayloadAction<{ mod: MenuItemMod; choiceId: string }>) => {
            if (state.menuItem) {
                const { mod, choiceId } = action.payload;
                if (mod.maxSelect === 1) {
                    const newSelection = Object.fromEntries(mod.choices.map((choice) => [choice.id, false]));
                    newSelection[choiceId] = true;
                    state.modSelections[mod.id] = newSelection;
                } else {
                    state.modSelections[mod.id][choiceId] = !state.modSelections[mod.id][choiceId];
                }
                const { orderMenuItem, price } = buildOrderMenuItem(
                    state.menuItem.id,
                    state.quantity,
                    state.modSelections,
                );
                state.orderMenuItem = orderMenuItem;
                state.price = price;
            }
        },
        setQuantity: (state, action: PayloadAction<number>) => {
            if (state.menuItem) {
                state.quantity = action.payload;
                const { orderMenuItem, price } = buildOrderMenuItem(
                    state.menuItem.id,
                    state.quantity,
                    state.modSelections,
                );
                state.orderMenuItem = orderMenuItem;
                state.price = price;
            }
        },
        clearMenuItem: (state) => {
            state.menuItem = null;
            state.quantity = 1;
            state.modSelections = {};
            state.orderMenuItem = null;
            state.price = 0;
        },
        addCartItem: (state, action: PayloadAction<OrderMenuItem>) => { 
            state.cartItems.push(clone(action.payload));
            state.menuItem = null;
            state.quantity = 1;
            state.modSelections = {};
            state.orderMenuItem = null;
            state.price = 0;
        },
        removeCartItem: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            if (0 <= index && index < state.cartItems.length) {
                const cartItems = state.cartItems;
                cartItems.splice(index, 1);
                state.cartItems = cartItems;
            }
        }
    },
});

function buildOrderMenuItem(
    menuItemId: string,
    quantity: number,
    modSelections: ReadByStr<ReadByStr<boolean>>,
): { orderMenuItem: DeepNotReadonly<OrderMenuItem> | null; price: number } {
    const menuItem = getMenuItem(menuItemId);
    if (menuItem === UNKNOWN_MENU_ITEM) {
        return { orderMenuItem: null, price: 0 };
    }

    let price = menuItem.price;
    let validMods = true;
    const mods: { [id: string]: string[] } = {};
    for (const modId of menuItem.mods) {
        const mod = getMenuItemMod(modId);
        if (mod === UNKNOWN_MENU_ITEM_MOD) {
            return { orderMenuItem: null, price: 0 };
        }

        if (!modSelections[mod.id]) {
            return { orderMenuItem: null, price: 0 };
        }

        const selectedChoices: string[] = [];
        for (const choice of mod.choices) {
            if (modSelections[mod.id][choice.id]) {
                selectedChoices.push(choice.id);
                price += choice.price;
            }
        }

        if (!(mod.minSelect <= selectedChoices.length && selectedChoices.length <= mod.maxSelect)) {
            validMods = false;
        }
        mods[mod.id] = selectedChoices;
    }
    price *= quantity;
    if (!validMods) {
        return { orderMenuItem: null, price };
    }

    const result = OrderMenuItemSchema.safeParse({
        menuItemId,
        quantity,
        mods,
        price,
        note: '',
    });
    if (result.success) {
        return { orderMenuItem: result.data, price };
    }
    return { orderMenuItem: null, price };
}

export default cartSlice.reducer;
