import React from 'react';
import './ErrorBoundary.css';

type State = {
    error: string;
};

type ErrorBoundaryProps = {
    children: React.ReactNode;
};
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: '' };
    }

    componentDidCatch(error: Error) {
        console.error('Error occured while rendering');
        console.error(error);
        this.setState({ error: `${error.name}: ${error.message}` });
    }

    render() {
        const { error } = this.state;
        if (error) {
            return <div className="ErrorBoundary">Something is wrong. Please try again later.</div>;
        } else {
            return <>{this.props.children}</>;
        }
    }
}
