import CenterMaxWidth from 'component/CenterMaxWidth/CenterMaxWidth';
import Button from '../Button/Button';
import classes from './MenuItemCategoryBar.module.css';

const containerStyle: React.CSSProperties = { borderBottom: '2px solid black' };

type MenuItemCategoryBarProp = {
    categories: readonly string[];
    onSelect: (categoty: string) => void;
};

export default function MenuItemCategoryBar(props: MenuItemCategoryBarProp) {
    const { categories, onSelect } = props;

    if (categories.length === 0) {
        return null;
    }
    return (
        <CenterMaxWidth width={1080} style={containerStyle}>
            <div className={classes.ButtonContainer}>
                {categories.map((category) => (
                    <Button key={category} text={category} onClick={() => onSelect(category)} />
                ))}
            </div>
        </CenterMaxWidth>
    );
}
