import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BuyerApp.css';
import { CheckoutPage, RestaurantPage } from './page';

export enum BuyerAppPage {
    Restaurant,
    Checkout,
}
type BuyerAppProps = {
    page: BuyerAppPage;
};
export default function BuyerApp({ page }: BuyerAppProps) {
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    useEffect(() => {
        if (page === BuyerAppPage.Restaurant && !restaurantId) {
            navigate('/');
        }
    }, [navigate, page, restaurantId]);

    if (page === BuyerAppPage.Checkout) {
        return <CheckoutPage />;
    }
    return <RestaurantPage restaurantId={restaurantId!!} />;
}
