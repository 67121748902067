import { RestaurantDataLoaded } from '../restaurantSlice';
import { useAppSelector } from '../store';
import { DataState } from 'types/api-types';

export const useRestaurantData = (): RestaurantDataLoaded => {
    const state = useAppSelector((state) => state.restaurantData.data);
    if (state.type !== DataState.Loaded) {
        throw Error('Invalid restaurant data state.');
    }
    return state;
};
