import BuyerApp, { BuyerAppPage } from 'buyer/BuyerApp';
import { ErrorBoundary } from 'component';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HomePage } from './buyer/page';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SellerApp from './seller/SellerApp';
import { store } from './state/store';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <ErrorBoundary>
                <HomePage />
            </ErrorBoundary>
        ),
    },
    {
        path: '/seller',
        element: (
            <ErrorBoundary>
                <SellerApp />
            </ErrorBoundary>
        ),
    },
    {
        path: '/restaurant/:restaurantId',
        element: (
            <ErrorBoundary>
                <BuyerApp page={BuyerAppPage.Restaurant}/>
            </ErrorBoundary>
        ),
    },
    {
        path: '/checkout/',
        element: (
            <ErrorBoundary>
                <BuyerApp page={BuyerAppPage.Checkout}/>
            </ErrorBoundary>
        ),
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
