import './IndentedText.css';

type ValueOrArray<T> = T | ValueOrArray<T>[];
export type IndentedTextInput = ValueOrArray<string>;

function buildContent(result: JSX.Element[], value: IndentedTextInput, depth: number, key: string) {
    if (typeof value === 'string') {
        result.push(
            <div key={key} style={{ marginLeft: `${depth * 20}px` }}>
                {value}
            </div>,
        );
    } else {
        value.forEach((v, index) => buildContent(result, v, depth + 1, key + index));
    }
}

export default function IndentedText({ value }: { value: IndentedTextInput }) {
    const content: JSX.Element[] = [];
    buildContent(content, value, typeof value === 'string' ? 0 : -1, 'indentedText');
    return <div className="IndentedTextContainer">{content}</div>;
}
