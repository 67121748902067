import { useCallback, useEffect, useRef, useState } from 'react';
import { useBills } from 'state/hooks/order';
import { Bill, DeliveryMethodType } from 'types/data-types';
import TableInfoModal from '../TableInfoModal/TableInfoModal';
import './TableView.css';

type Table = {
    type: 'TABLE' | 'BARRIER';
    x: number;
    y: number;
    width: number;
    height: number;
    tableId: string;
};
const FLOOR_BOUND = {
    width: 1500,
    height: 1500,
};
const MOCK_TABLES: Table[] = [
    {
        type: 'TABLE',
        x: 500,
        y: 0,
        width: 300,
        height: 200,
        tableId: '1',
    },
    {
        type: 'TABLE',
        x: 1000,
        y: 0,
        width: 300,
        height: 200,
        tableId: '2',
    },
    {
        type: 'TABLE',
        x: 450,
        y: 350,
        width: 550,
        height: 200,
        tableId: '3',
    },
    {
        type: 'TABLE',
        x: 1200,
        y: 350,
        width: 300,
        height: 200,
        tableId: '4',
    },
    {
        type: 'TABLE',
        x: 450,
        y: 700,
        width: 550,
        height: 200,
        tableId: '5',
    },
    {
        type: 'TABLE',
        x: 1200,
        y: 700,
        width: 300,
        height: 200,
        tableId: '6',
    },
    {
        type: 'TABLE',
        x: 450,
        y: 1000,
        width: 550,
        height: 200,
        tableId: '7',
    },
    {
        type: 'TABLE',
        x: 0,
        y: 300,
        width: 200,
        height: 200,
        tableId: '8',
    },
    {
        type: 'TABLE',
        x: 0,
        y: 700,
        width: 200,
        height: 200,
        tableId: '9',
    },
    {
        type: 'BARRIER',
        x: 1200,
        y: 1100,
        width: 300,
        height: 300,
        tableId: '-1',
    },
    {
        type: 'BARRIER',
        x: 200,
        y: 1300,
        width: 800,
        height: 200,
        tableId: '-2',
    },
];

const renderTable = (
    { scaleX, scaleY }: { scaleX: number; scaleY: number },
    table: Table,
    bill: Bill | undefined,
    onClick: (tableId: string) => void,
): JSX.Element => {
    const isTable = table.type === 'TABLE';
    const onTableClick = () => {
        if (isTable) {
            onClick(table.tableId);
        }
    };
    return (
        <div
            key={table.tableId}
            style={{
                position: 'absolute',
                top: table.y * scaleY,
                left: table.x * scaleX,
                width: table.width * scaleX,
                height: table.height * scaleY,
                backgroundColor: !isTable ? 'grey' : bill ? '#67db00' : '#336699',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 50,
            }}
            onClick={onTableClick}
        >
            {isTable ? table.tableId : null}
        </div>
    );
};
export default function TableView() {
    const viewRef = useRef<HTMLDivElement>(null);
    const [scaleXY, setScaleXY] = useState<{ scaleX: number; scaleY: number } | null>(null);
    const [tableInfoModal, setTableInfoModal] = useState<string | null>(null);
    const bills = useBills();

    const onTableClick = useCallback((tableId: string) => {
        setTableInfoModal(tableId);
    }, []);

    useEffect(() => {
        if (viewRef.current) {
            const { offsetWidth, offsetHeight } = viewRef.current;
            const { width, height } = FLOOR_BOUND;
            /* 
            const boundByWidth = width / height > offsetWidth / offsetHeight;
            const scale = boundByWidth ? offsetWidth / width : offsetHeight / height;
            */
            setScaleXY({ scaleX: offsetWidth / width, scaleY: offsetHeight / height });
        }
    }, [setScaleXY]);

    let tableView: JSX.Element | null = null;
    if (scaleXY) {
        const { scaleX, scaleY } = scaleXY;
        const { width, height } = FLOOR_BOUND;
        const tableViews: JSX.Element[] = MOCK_TABLES.map((table) => {
            const billForTable = Object.values(bills).find(
                (bill) =>
                    bill.deliveryMethod.type === DeliveryMethodType.Enum.DineIn &&
                    bill.deliveryMethod.data.table === table.tableId,
            );
            return renderTable(scaleXY, table, billForTable, onTableClick);
        });

        tableView = (
            <div
                style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    width: width * scaleX,
                    height: height * scaleY,
                    backgroundColor: '#c2a465',
                }}
            >
                {tableViews}
            </div>
        );
    }

    return (
        <div className="TableView" ref={viewRef}>
            {tableView}
            {tableInfoModal && <TableInfoModal onClose={() => setTableInfoModal(null)} tableId={tableInfoModal} />}
        </div>
    );
}
