import { cartSlice } from 'state/cartSlice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { MenuItemMod } from 'types/data-types';
import { formatPrice } from 'util/PriceUtil';
import classes from './SelectModComponent.module.css';

type SelectModComponentProp = {
    menuItemMod: MenuItemMod;
};
export default function SelectModComponent({ menuItemMod }: SelectModComponentProp) {
    const dispatch = useAppDispatch();
    const modSelections = useAppSelector((state) => state.cartData.modSelections[menuItemMod.id]);
    const selectedCount = Object.values(modSelections).reduce((acc, val) => acc + (val ? 1 : 0), 0);

    let selectHintText = '';
    if (menuItemMod.minSelect === menuItemMod.maxSelect) {
        selectHintText = `Choose ${menuItemMod.minSelect}`;
    } else if (menuItemMod.maxSelect < menuItemMod.choices.length) {
        selectHintText = `Choose up to ${menuItemMod.maxSelect}`;
    }

    let selectHintColor = undefined;
    let selectedChoiceColor = 'lightgreen';
    if (selectedCount > menuItemMod.maxSelect) {
        selectHintColor = '#ff0000';
        selectedChoiceColor = '#ff5555';
    } else if (menuItemMod.minSelect > 0) {
        selectHintColor = '#ff7700';
    }

    return (
        <div className={classes.SelectModComponentContainer}>
            <div className={classes.SelectModComponentTopContainer}>
                <div className={classes.SelectModComponentName}>{menuItemMod.name}</div>
                <div className={classes.SelectModComponentHint} style={{ color: selectHintColor }}>
                    {selectHintText}
                </div>
            </div>
            <div className={classes.SelectModComponentChoicesContainer}>
                {menuItemMod.choices.map((choice) => (
                    <div
                        key={choice.id}
                        className={classes.SelectModComponentChoiceContainer}
                        onClick={() =>
                            dispatch(
                                cartSlice.actions.toggleModChoice({
                                    mod: menuItemMod,
                                    choiceId: choice.id,
                                }),
                            )
                        }
                        style={{ backgroundColor: modSelections[choice.id] ? selectedChoiceColor : undefined }}
                    >
                        <div className={classes.SelectModComponentChoiceName}>{choice.name}</div>
                        {choice.price > 0 && (
                            <div className={classes.SelectModComponentChoicePrice}>{formatPrice(choice.price)}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
