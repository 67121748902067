import { LoadingSpinner } from 'component';
import { useState } from 'react';
import { orderDataSlice } from 'state/orderSlice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { DataState } from 'types/api-types';
import './SellerApp.css';
import { HeaderBar } from './component';
import { AddOrderPage, BillPage, RestaurantPage, SignInPage } from './page';

export default function SellerApp() {
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector((state) => state.userData.accessToken);
    const restaurantData = useAppSelector((state) => state.restaurantData.data);
    const orderData = useAppSelector((state) => state.orderData.data);
    const [currentPage, setCurentPage] = useState('HOME');

    const homeClick = () => {
        dispatch(orderDataSlice.actions.clearOrderCreation());
        setCurentPage('HOME');
    };
    const billClick = () => {
        dispatch(orderDataSlice.actions.clearOrderCreation());
        setCurentPage('BILL');
    };

    if (!accessToken) {
        return (
            <div className="SellerApp">
                <SignInPage />
            </div>
        );
    }

    if (restaurantData.type !== DataState.Loaded || orderData.type !== DataState.Loaded) {
        const error = restaurantData.type === DataState.Error || orderData.type === DataState.Error;
        const content = error ? 'Something is wrong. Please try again later.' : <LoadingSpinner />;
        return <div className="SellerApp">{content}</div>;
    }

    let pageContent;
    if (orderData.currentOrderCreation) {
        pageContent = <AddOrderPage />;
    } else if (currentPage === 'BILL') {
        pageContent = <BillPage />;
    } else {
        pageContent = <RestaurantPage />;
    }
    return (
        <div className="SellerApp">
            <div className="PageContainer">
                <HeaderBar homeClick={homeClick} billClick={billClick} />
                <div className="PageContent">{pageContent}</div>
            </div>
        </div>
    );
}
