import { CenterMaxWidth } from 'component';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { callCustomerCheckout } from 'state/api';
import { useAppSelector } from 'state/store';
import c from './CheckoutPage.module.css';

const PROD_SQUARE_APP_ID = process.env.REACT_APP_PROD_SQUARE_APP_ID!!;
const PROD_SQUARE_LOCATION_ID = process.env.REACT_APP_PROD_SQUARE_LOCATION_ID!!;
const SANDBOX_SQUARE_APP_ID = process.env.REACT_APP_SANDBOX_SQUARE_APP_ID!!;
const SANDBOX_SQUARE_LOCATION_ID = process.env.REACT_APP_SANDBOX_SQUARE_LOCATION_ID!!;
const isSandbox = true;

export default function CheckoutPage() {
    const navigate = useNavigate();
    const { restaurantId, cartItems } = useAppSelector((state) => state.cartData);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('Doe');

    useEffect(() => {
        if (!restaurantId || cartItems.length === 0) {
            navigate('/');
        }
    }, [navigate, restaurantId, cartItems]);

    const validInput = firstName && lastName;

    return (
        <CenterMaxWidth width={1080}>
            <div className={c.CheckoutPage}>
                <div className={c.Header}>Checkout</div>

                <div className={c.InputRow}>
                    <div>Name:</div>
                    <input
                        className={c.Input}
                        onChange={(event) => setFirstName(event.target.value)}
                        value={firstName}
                    />
                    <input className={c.Input} onChange={(event) => setLastName(event.target.value)} value={lastName} />
                </div>

                <CenterMaxWidth width={540} style={{ marginTop: '300px' }}>
                    <PaymentForm
                        applicationId={isSandbox ? SANDBOX_SQUARE_APP_ID : PROD_SQUARE_APP_ID}
                        cardTokenizeResponseReceived={(token) => {
                            if (token.token) {
                                callCustomerCheckout(
                                    isSandbox,
                                    token.token,
                                    restaurantId!!,
                                    firstName,
                                    lastName,
                                    cartItems,
                                )
                                    .then((data) => {
                                        console.log('success');
                                        console.log(data);
                                        navigate('/');
                                    })
                                    .catch((e) => console.error(e));
                            }
                        }}
                        locationId={isSandbox ? SANDBOX_SQUARE_LOCATION_ID : PROD_SQUARE_LOCATION_ID}
                    >
                        <CreditCard buttonProps={{ isLoading: !validInput }} />
                    </PaymentForm>
                </CenterMaxWidth>
            </div>
        </CenterMaxWidth>
    );
}
