import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './Button.css';

export enum ButtonColor {
    Gray = '#b3b3b3',
    Default = 'burlywood',
}

type ButtonProp = {
    text: string;
    color?: ButtonColor;
    inProgress?: boolean;
    disabled?: boolean;
    onClick?: () => void;
};

export default function Button(props: ButtonProp) {
    const { text, color, inProgress, disabled, onClick } = props;

    const backgroundColor = disabled ? ButtonColor.Gray : color || ButtonColor.Default;
    return (
        <div className="ButtonContainer" onClick={disabled ? undefined : onClick} style={{ backgroundColor }}>
            <div style={{ opacity: inProgress ? 0 : 1 }}>{text}</div>
            {inProgress && (
                <div className="ButtonSpinnerContainer">
                    <LoadingSpinner />
                </div>
            )}
        </div>
    );
}
